import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/seo";
import Par from "../components/Par";
import { listOfTestimonials } from "../data/listOfTestimonials";
import Testimonial from "../components/Testimonial";

const noOfPreviewTestimonials = 3;

function IndexPage() {
	return (
		<StaticQuery
			query={graphql`
				query {
					team: file(relativePath: { eq: "advanced-gi-team.jpg" }) {
						childImageSharp {
							fluid(maxHeight: 400) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
					hero: file(relativePath: { eq: "advanced-gi-home-1.jpg" }) {
						childImageSharp {
							fluid(maxHeight: 412) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			`}
			render={({ team, hero }) => (
				<div>
					<SEO
						title="Gastroenterology & Endoscopy Specialists"
						keywords={[
							`Gastroenterologist`,
							`Paediatric`,
							`Dr Spiro Raftopoulos`,
							`Dr Ian Yusoff`,
							`Dr Marcelo Leal`,
							`Dr Daniel Cheng`,
							`Dr Sam Rao`,
							`Dr Claire Gordon`,
							`Dr Duy Pham`,
							`Dr Angel Li`,
							`Dr Danielle Lam,`,
							`Dr Katrina Rogers`,
							`Dr Muna Salama`,
						]}
						description="Home of WA's leading gastroenterologists and hepatologists"
					/>
					<div>
						<div className="relative w-screen hero">
							<Img
								style={{ objectFit: "cover", maxHeight: "auto" }}
								fluid={hero.childImageSharp.fluid}
								alt="gastroenterology-home"
							/>
							<h1
								className="text-white z-10 absolute p-4 md:text-3xl sm:text-xl"
								style={{ background: `rgba(20, 87, 123, 0.7)`, left: "10%", top: "20%", width: "17rem" }}
							>
								Welcome to Advanced GI WA
							</h1>
						</div>
						<h2 className="h1">Welcome to Advanced GI WA</h2>
						{/* <h2 className="h1">(We are presently closed for the Christmas break and will reopen on the 10th January 2022)</h2> */}
						<Img fluid={team.childImageSharp.fluid} alt="Advanced Gastroenterology Team" />
						<Par
							extraClasses="mt-6"
							text="Advanced GI WA is a progressive Gastroenterology practice based in Perth, Western Australia. We offer our patients leading - edge treatments and a comprehensive service that covers all aspects of luminal gastroenterology and hepatology."
						/>
						<Par text="We are a one-stop gastroenterology destination for consultation, diagnostic testing and therapeutic procedures. Our specialist team has extensive knowledge and experience providing the highest level of medical care to all our patients. As we are all tertiary hospital affiliated, our team has access to clinical trials, multi-disciplinary meetings and we keep up with the latest in diagnostics and medical therapy." />
						<Par text="All endoscopic procedures are performed at Hollywood Private Hospital were we have access to the most current technologies and up to date equipment to diagnose and treat complicated digestive disorders." />
						{listOfTestimonials.length > 0 ? (
							<>
								<h2 className="h1">Testimonials</h2>
								{listOfTestimonials.slice(0, noOfPreviewTestimonials).map(testimonial => (
									<Testimonial testimonial={testimonial} />
								))}
								<div className="text-center mt-5">
									{/* <Link to="/testimonials"> */}
									<Link to=" ">
										<button
											className="hover:bg-primary-dark bg-primary text-white py-1 px-4 rounded mx-auto"
											type="button"
										>
											See all testimonials
										</button>
									</Link>
								</div>
							</>
						) : null}
					</div>
				</div>
			)}
		/>
	);
}

export default IndexPage;
